<template>
  <div class="z-10">
    <!-- large version calendar -->
    <div class="hidden lg:block relative">
      <button
        @click="calendarActive"
        class="
          font-semibold
          px-4
          focus:outline-none
          rounded
          text-sm
          border border-black
          hover:border-radio-50 hover:text-radio-50
          flex
          items-center
          justify-center
        "
      >
        <span class=""> <Iconcalendar /></span>
        <span class="p-1 px-2">{{ selectedFilter }}</span>
        <span> <Iconchevroncal /></span>
      </button>
      <div
        v-if="calendar"
        class="
          ffmaily-pro
          absolute
          text-base
          mt-2
          right-0
          -ml-64
          flex
          border border-kborder-500
          rounded
        "
      >
        <div class="bg-white p-6 w-48 font-normal lg:border border-kborder-9">
          <ul class="grid gap-4">
            <li
              :class="i == selectedFilter ? 'text-tex-2' : ''"
              v-for="i in calFilter"
              :key="i.id"
            >
              <button
                @click="timeFilter(i)"
                type="button"
                class="focus:outline-none"
              >
                {{ i }}
              </button>
            </li>
          </ul>
        </div>
        <div class="bg-white py-5 px-5">
          <span class="mb-4">
            <vc-date-picker
              mode="range"
              v-model="range"
              :max-date="new Date()"
              is-inline
              title-position="left"
              :theme="theme"
            />
          </span>
          <span class="pt-5 pl-3">
            <button
              type="button"
              @click="calendar = !calendar"
              class="
                border
                text-kbutton-1
                border-kbutton-1
                h-search
                sm:h-minput
                hover:border-kbutton-2 hover:text-kbutton-2
                active:border active:border-kbutton-3
                py-1
                sm:py-2
                px-4
                sm:px-6
                text-sm
                sm:text-base
                rounded
                focus:outline-none
              "
            >
              {{ $t("homeDash.cancelBtn") }}
            </button>
            <button
              type="button"
              @click="applyFilter"
              class="
                ml-4
                text-white
                border
                h-search
                sm:h-minput
                bg-kbutton-1
                hover:bg-kbutton-2
                active:border active:border-kbutton-3
                py-1
                sm:py-2
                px-4
                sm:px-6
                text-sm
                sm:text-base
                rounded
                focus:outline-none
              "
            >
              {{ $t("homeDash.apllyBtn") }}
            </button>
          </span>
        </div>
      </div>
    </div>
    <!-- large version calendar -->

    <!-- mobile version calendar -->
    <div class="lg:hidden relative">
      <button
        @click="calendarActive"
        class="
          font-semibold
          px-4
          focus:outline-none
          rounded
          text-sm
          border border-black
          hover:border-radio-50 hover:text-radio-50
          flex
          items-center
          justify-center
        "
      >
        <span class=""> <Iconcalendar /></span>
        <span class="p-1 px-2">{{ selectedFilter }}</span>
        <span> <Iconchevroncal /></span>
      </button>
      <div
        v-if="calendar"
        class="
          ffmaily-pro
          absolute
          text-base
          mt-2
          right-0
          -ml-64
          flex
          border border-kborder-500
          rounded
        "
      >
        <div
          v-if="!chooseDate"
          class="bg-white p-6 w-48 font-normal lg:border border-kborder-9"
        >
          <ul class="grid gap-4">
            <li
              :class="i == selectedFilter ? 'text-tex-2' : ''"
              v-for="i in calFilter"
              :key="i.id"
            >
              <button
                @click="timeFilter(i)"
                type="button"
                class="focus:outline-none"
              >
                {{ i }}
              </button>
            </li>
            <li @click="chooseDate = true">
              <button class="flex items-center focus:outline-none">
                <span class="text-tex-2 mr-2">{{
                  $t("homeDash.chooseDate")
                }}</span
                ><Iconchevrdate />
              </button>
            </li>
          </ul>
        </div>
        <div v-else class="bg-white py-5 px-5">
          <span class="mb-4">
            <vc-date-picker
              mode="range"
              v-model="range"
              :max-date="new Date()"
              is-inline
              title-position="left"
              :theme="theme"
            />
          </span>
          <span class="pt-5 pl-3">
            <button
              type="button"
              @click="calendar = !calendar"
              class="
                border
                text-kbutton-1
                border-kbutton-1
                h-search
                sm:h-minput
                hover:border-kbutton-2 hover:text-kbutton-2
                active:border active:border-kbutton-3
                py-1
                sm:py-2
                px-4
                sm:px-6
                text-sm
                sm:text-base
                rounded
                focus:outline-none
              "
            >
              {{ $t("homeDash.cancelBtn") }}
            </button>
            <button
              type="button"
              @click="applyFilter"
              class="
                ml-4
                text-white
                border
                h-search
                sm:h-minput
                bg-kbutton-1
                hover:bg-kbutton-2
                active:border active:border-kbutton-3
                py-1
                sm:py-2
                px-4
                sm:px-6
                text-sm
                sm:text-base
                rounded
                focus:outline-none
              "
            >
              {{ $t("homeDash.apllyBtn") }}
            </button>
          </span>
        </div>
      </div>
    </div>
    <!-- mobile version calendar -->
  </div>
</template>

<script>
import Iconcalendar from "../assets/svg/dashbIcons/calendar.svg?inline";
import Iconchevroncal from "../assets/svg/dashbIcons/chevroncalendar.svg?inline";
import Iconchevrdate from "../assets/svg/dashbIcons/chevronDate.svg?inline";
import VcDatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    Iconcalendar,
    Iconchevroncal,
    VcDatePicker,
    Iconchevrdate,
  },
  data() {
    return {
      calFilter: [
        this.$i18n.t("homeDash.calBtn"),
        this.$i18n.t("homeDash.today"),
        this.$i18n.t("homeDash.passWeek"),
        this.$i18n.t("homeDash.passMonth"),
        this.$i18n.t("homeDash.passYear"),
      ],
      selectedFilter: this.$i18n.t("homeDash.calBtn"),
      chooseDate: false,
      calendar: false,
      filterRange: {
        startDate: "allTime",
        endDate: "endAllTime",
      },
      range: {
        start: "", // Jan 16th, 2018
        end: "", // Jan 19th, 2018
      },
      theme: {
        arrows:
          "text-black vc-rounded vc-border-2 vc-border-transparent hover:vc-opacity-50 hover:vc-bg-gray-300 focus:vc-border-gray-300",
        bgAccentHigh: "bg-tex-2",
        bgAccentLow: "bg-white",
        bgLow: "bg-tex-2 vc-border-2 vc-border-tex-2",
        color: "red",
        container: "text-black text-base font-normal vc-bg-white",
        contentAccent: "text-black",
        contentAccentContrast: "vc-text-white",
        dayContent: "text-black",
        dayContentDisabled: "vc-text-gray-400",
        header: "text-black",
        weekdays: "text-black",
        highlightBaseFillMode: "light",
        highlightBaseClass: "",
        highlightBaseContentClass: "bg-tex-9",
        highlightStartEndClass: "",
        highlightStartEndFillMode: "solid",
        highlightStartEndContentClass: "bg-tex-2",
        headerTitle: "text-black",
      },
    };
  },
  methods: {
    timeFilter(filter) {
      this.selectedFilter = filter;

      if (filter == this.$i18n.t("homeDash.calBtn")) {
        this.filterRange = {
          startDate: "allTime",
          endDate: "endAllTime",
        };
        this.$emit("change", this.filterRange);
      } else if (filter == this.$i18n.t("homeDash.today")) {
        let today = new Date();
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        today = today.toISOString().slice(0, 10);
        tomorrow = tomorrow.toISOString().slice(0, 10);
        console.log(today, tomorrow);
        this.filterRange = {
          startDate: today,
          endDate: tomorrow,
        };
        console.log(this.filterRange);
        this.$emit("change", this.filterRange);
      } else if (filter == this.$i18n.t("homeDash.passWeek")) {
        let today = new Date();
        let weekAgo = new Date(today);
        weekAgo.setDate(weekAgo.getDate() - 7);
        today = today.toISOString().slice(0, 10);
        weekAgo = weekAgo.toISOString().slice(0, 10);
        console.log(today, weekAgo);
        this.filterRange = {
          startDate: weekAgo,
          endDate: today,
        };
        console.log("week", this.filterRange);
        this.$emit("change", this.filterRange);
      } else if (filter == this.$i18n.t("homeDash.passMonth")) {
        let today = new Date();
        let monthAgo = new Date(today);
        monthAgo.setDate(monthAgo.getDate() - 30);
        today = today.toISOString().slice(0, 10);
        monthAgo = monthAgo.toISOString().slice(0, 10);
        console.log(today, monthAgo);
        this.filterRange = {
          startDate: monthAgo,
          endDate: today,
        };
        this.$emit("change", this.filterRange);
      } else if (filter == this.$i18n.t("homeDash.passYear")) {
        let today = new Date();
        let yearAgo = new Date(today);
        yearAgo.setDate(yearAgo.getDate() - 365);
        today = today.toISOString().slice(0, 10);
        yearAgo = yearAgo.toISOString().slice(0, 10);
        console.log(today, yearAgo);
        this.filterRange = {
          startDate: yearAgo,
          endDate: today,
        };
        this.$emit("change", this.filterRange);
      }
      this.calendar = !this.calendar;
    },

    applyFilter() {
      console.log(this.range);
      if (!this.range) {
        console.log("not range date");
        this.showToast = true;
        return;
      }
      let start = new Date(this.range.start);
      start.setDate(start.getDate() + 1);
      let end = new Date(this.range.end);
      end.setDate(end.getDate() + 1);
      start = start.toISOString().slice(0, 10);
      end = end.toISOString().slice(0, 10);
      console.log("start" + start, end);
      this.filterRange = {
        startDate: start,
        endDate: end,
      };
      console.log("datePicker", this.filterRange);
      this.$emit("change", this.filterRange);
      this.calendar = !this.calendar;
    },
    calendarActive() {
      this.chooseDate = false;
      this.calendar = !this.calendar;
    },
  },
  watch: {
    theme(val) {
      console.log(val);
      this.theme = val;
    },
  },
  mounted() {
    this.theme = {
      arrows:
        "text-black vc-rounded vc-border-2 vc-border-transparent hover:vc-opacity-50 hover:vc-bg-gray-300 focus:vc-border-gray-300",
      bgAccentHigh: "bg-tex-2",
      bgAccentLow: "bg-white",
      bgLow: "bg-tex-2 vc-border-2 vc-border-tex-2",
      color: "red",
      container: "text-black text-base font-normal vc-bg-white",
      contentAccent: "text-black",
      contentAccentContrast: "vc-text-white",
      dayContent: "text-black",
      dayContentDisabled: "vc-text-gray-400",
      header: "text-black",
      weekdays: "text-black",
      highlightBaseFillMode: "light",
      highlightBaseClass: "",
      highlightBaseContentClass: "bg-tex-9",
      highlightStartEndClass: "",
      highlightStartEndFillMode: "solid",
      highlightStartEndContentClass: "bg-tex-2",
      headerTitle: "text-black",
    };
    console.log('theme', this.theme);
  },
};
</script>