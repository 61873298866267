<template>
    <div v-if="show" class="ffamily-pro font-semibold flex items-center p-3 rounded-lg fixed z-20 "
    style="top: 16px;right: 16px;max-width: 360px;"
    :class="[position, color]">
        <span>
            <slot name="icon">
              <Icontoasche />
            </slot>
        </span>
        <span class="ml-2 text-sm md:text-base text-white">
            <slot>{{message}}</slot>
        </span>
            
    </div>
</template>

<script>

import Icontoasche from "../assets/svg/icons/toascheck.svg?inline";
export default {
  components:{
      Icontoasche
  },
  props: {
    position: {
      type: String,
      default: 'top-right'
    },
    color: {
      type: String,
      default: 'bg-bgk-3'
    },
    message: String,

    show: {
      type: Boolean,
      default: false
    },

    timeout: {
      type: Number,
      default: 5000
    }
  },

  methods: {
    hideToast() {
      console.log('close emit');
      this.$emit('close', true)
    }
  },

  watch: {
    show(val) {
      if (val) {
      setTimeout(() => {
          this.hideToast()
        }, this.timeout);
      }
    }
  }
  
}



</script>

<style scoped>

</style>