<template>
  <div>
    <div class="tabs-header">
      <div
        :class="'tabs-header-item' + (tab.active ? ' tabs-header-item-active' : '')"
        v-for="(tab, index) in tabsItems"
        :key="tab.key"
        @click="setActive(index)"
      >
        {{ tab.title }}
        <div class="red-rule" v-if="tab.active"></div>
      </div>
    </div>
    <div class="tabs-content">
      <div v-for="tab in tabsItems" :key="tab.key">
        <keep-alive>
          <slot v-if="tab.active" :name="tab.key" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      tabsItems: this.items.map((it) => ({ ...it })),
    };
  },
  watch: {
    items(value) {
      this.tabsItems = value.map((it) => ({ ...it }));
    },
  },
  methods: {
    setActive(selectedIndex) {
      this.tabsItems = this.tabsItems.map((it, index) => ({
        ...it,
        active: selectedIndex === index,
      }));
    },
  },
};
</script>

<style scoped>
.tabs-header {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.tabs-header-item {
  padding: 12px 16px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.tabs-header-item-active {
  font-weight: bold;
}

.red-rule {
  height: 4px;
  background-color: #f11c33;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -1px;
}
</style>